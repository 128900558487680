/*
 * @Author: wxy
 * @Describe:
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-21 20:33:55
 */

import { createRouter, createWebHashHistory } from "vue-router";
import path from "./routeHandle";
import navigationGuard from "./navigationGuard";

// 添加404 
let noFindRoute = {
  path: "/:pathMatch(.*)*",
  name: "404",
  component: () => import("../" + "views/404/404" + ".vue"),
};

// 遍历处理路由
const ROUTERS = path.concat(noFindRoute);

// console.log('路由文件',ROUTERS)
// console.log('菜单文件',menuArr);

const router = createRouter({
  history: createWebHashHistory(),
  routes: ROUTERS,
});

// 設置路由鉤子
navigationGuard(router);

export default router;
