/*
 * @Author: wxy
 * @Describe:
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-21 22:57:36
 */

export default [

    {
    path: "/user",
    name: "User",
    file: "views/user/user",
    meta: {
      icon: "UserOutlined",
      breadcrumbName: "用户数据",
      token: true,
    },
  },
  {
    path: "/ranking",
    name: "Ranking",
    file: "views/ranking/ranking",
    meta: {
      icon: "TrophyOutlined",
      breadcrumbName: "排名数据",
      token: true,
    },
  },
  {
    path: "/address",
    name: "Address",
    file: "views/address/address",
    meta: {
      icon: "EnvironmentOutlined",
      breadcrumbName: "地址数据",
      token: true,
    },
  }
];
