/*
 * @Author: wxy
 * @Describe: 全局上的一些配置字段
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-21 22:53:35
 */

const club = "_w_QQ_start_";

export const globalConfig = {
  club,
  clubText: "QQ星养牛管理后台",
  logo:"QQ星",
  field: {
    token: club + "token",
    info: club + "info",
    type: club + "type",
    historyRoute: club + "history_route",
  },
};
