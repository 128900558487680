/*
 * @Author: wxy
 * @Describe: 缓存处理方法
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-20 02:32:33
 */

//获取缓存
export function getValue(key) {
  return checkValue(key, true);
}

// 储存数据
export function setValue(key, value, seconds = 3600 * 24 * 7) {
  // 数据体
  let storageValue = {
    value,
    expire: 0,
  };
  if (key && value === null) {
    throw Error("[storage]value值不能为空");
  } else if (key && value) {
    //设置缓存
    storageValue.expire = seconds === 0 ? seconds : getDateNow() + seconds;
    localStorage.setItem(key, JSON.stringify(storageValue));
  }
}

// 检查缓存是否有效
export function checkValue(key, resValue = false) {
  if (!key) {
    console.error("[storage]key值不能为空");
    return;
  }
  let storageValue = JSON.parse(localStorage.getItem(key));

  // console.log('獲取到',storageValue)

  // 数据不存在
  if (!storageValue) return false;

  // 如果数据存在并且缓存时间为零，永久缓存
  if (storageValue.value && storageValue.expire === 0) {
    return resValue ? storageValue.value : true;
  }

  // 如果数据为空或者已超过缓存时间返回false
  if (!storageValue.value || getDateNow() >= storageValue.expire) {
    localStorage.removeItem(key);
    return false;
  }
  // 如果数据存在并且缓存时间还大于30分钟返回true
  if (storageValue.value && getDateNow() + 180 <= storageValue.expire) {
    return resValue ? storageValue.value : true;
  }
}

export function delValue(key) {
  localStorage.removeItem(key);
}

// 获取当前时间（秒）
function getDateNow() {
  return Date.parse(new Date()) / 1000;
}
