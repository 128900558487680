/*
 * @Author: wxy
 * @Describe: 設置路由全局鉤子
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-21 21:00:11
 */

import NProgress from "nprogress"; // Progress 进度条
import { message } from "ant-design-vue";
import { checkValue } from "@/common/util/storage";
import { globalConfig } from "@/common/config/global.config";

export default function setRouteGuard(router) {
  // 前置鉤子
  router.beforeEach((to, from, next) => {
    // 檢查是否需要TOKEN
    if (to.meta.token) {
      if (checkValue(globalConfig.field.token)) {
        // console.log(from);
      } else {
        next({ name: "login" });
        message.error("請登錄後訪問！");

      }
    }
    NProgress.start(); // 顯示進度條
    next();
  });
  // 後置鉤子
  router.afterEach(() => {
    NProgress.done(); // 關閉進度條
  });
  // 錯誤鉤子
  router.onError((err) => {
    message.error(err);
    console.error("路由錯誤：", err);
    NProgress.done(); // 關閉進度條
  });
}
