/*
 * @Author: wxy
 * @Describe:
 * @LastEditors: wxy
 * @LastEditTime: 2021-04-16 18:42:02
 */
import path from "./path/path";

/**
 *
 * @param name // 路由名称
 * @param path // 路由路径
 * @param file // 路由文件路径
 * @param children // 子路由
 * @param meta // 面包屑名称
 * @returns {{path: *, component: (function(): *), name: *}}
 */

function handleRoute({ name, path, file, children, meta }) {
  const routeItem = {
    name,
    path,
    meta,
    component: () => import(`../${file}.vue`),
  };
  // 检测是否拥有嵌套路由
  let childrenArray;

  // 处理子路由
  if (children) {
    childrenArray = children
      .filter((item) => item.file && item.name && item.path)
      .map((item) => handleRoute(item));
    routeItem.children = childrenArray;
    routeItem.path = path;
  }

  return routeItem;
}

// 面包屑处理
function breadcrumbHandle(childrenArray) {
  if (!childrenArray || !Array.isArray(childrenArray) || !childrenArray.length)
    return;
  childrenArray.forEach((route) => {
    route.meta.breadcrumbNameArr = [];
    // 父级处理
    if (route.meta.parent) {
      route.meta.breadcrumbNameArr = childrenArray
        .filter((value) => route.meta.parent === value.name)
        .map((value) => value.meta.breadcrumbName); // 添加父级
      route.meta.breadcrumbNameArr.push(route.meta.breadcrumbName); // 添加自己
      route.meta.breadcrumbNameArr = route.meta.breadcrumbNameArr.join(); // 转换成字符串
    }
    // 无父级
    else {
      route.meta.breadcrumbNameArr = route.meta.breadcrumbName;
    }
  });
}

// 处理面包屑
path.forEach((item) => breadcrumbHandle(item.children));

// 生成路由文件
let route = path
  .filter((item) => item.file && item.name && item.path)
  .map((item) => handleRoute(item));

export default route;
