/*
 * @Author: wxy
 * @Describe:
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-09 23:08:03
 */
import indexChildren from "./indexChildren";

// file字段前面不要加 "/"
export default [
  {
    name: "login",
    path: "/",
    file: "views/login/login",
    meta: {
      breadcrumbName: "登录",
    },
  },
  {
    name: "register",
    path: "/register",
    file: "views/register/register",
    meta: {
      breadcrumbName: "注册",
    },
  },
  {
    name: "index",
    path: "/index",
    file: "layout/layout",
    redirect: "/EssentialInfo",
    children: indexChildren,
    meta: {
      breadcrumbName: "首页",
    },
  },
];
