/*
 * @Author: wxy
 * @Describe: 
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-20 01:50:06
 */

import { createVNode } from 'vue'
import * as $Icon from '@ant-design/icons-vue'

export const Icon = (props,context) => {
  const { icon } = props;
  return createVNode($Icon[icon],{ class:context.attrs.class });
};