<!--
 * @Author: wxy
 * @Describe: 
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-20 19:28:06
-->
<template>
  <a-config-provider :locale="zhCN">
    <router-view></router-view>
  </a-config-provider>
</template>

<script>
import { defineComponent } from "vue";
import zhCN from "ant-design-vue/es/locale/zh_CN";

export default defineComponent({
  setup() {
    return {
      zhCN,
    };
  },
});
</script>

<style>
@import "./assets/scss/main.css";
</style>

<style lang="scss">
@import "./assets/scss/theme.config.scss";
</style>
