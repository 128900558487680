/*
 * @Author: wxy
 * @Describe:
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-20 01:18:40
 */

import { createApp } from "vue";
import antd from "ant-design-vue";
import App from "./App.vue";
import router from "./route";
import "ant-design-vue/dist/antd.css";
import "nprogress/nprogress.css"; // Progress 进度条样式
import { globalConfig } from "./common/config/global.config"; // 全局参数和自定义配置
import { Icon } from "@/components/Wicon/Wicon";

const app = createApp(App);

app.component("Wicon", Icon);
app.use(router);
app.use(antd);
app.provide("globalConfig", globalConfig);
// app全局
app.mount("#app");
